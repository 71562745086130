import React, { useState, useEffect, useRef } from "react";
import "../styles/BottomSheet.css";
import bottomSheetBGLeft from "../assets/images/bottomSheetBGLeft.png";
import bottomSheetBGCenterSliver from "../assets/images/bottomSheetBGCenterSliver.png";
import bottomSheetBGRight from "../assets/images/bottomSheetBGRight.png";
import cancelButton from "../assets/images/cancelButton.png";
import cancelButtonPressed from "../assets/images/cancelButtonPressed.png";
import confirmButton from "../assets/images/confirmButton.png";
import confirmButtonPressed from "../assets/images/confirmButtonPressed.png";
import confirmButtonDisabled from "../assets/images/confirmButtonDisabled.png";
import {
  playSoundDeselect,
  playSoundSelectionPrimary,
} from "../utils/AudioManager";

const STATES = {
  CLOSED: "CLOSED",
  OPENING: "OPENING",
  OPEN: "OPEN",
  CLOSING: "CLOSING",
};

function BottomSheet({
  children,
  onCancel,
  onConfirm,
  canTapOutToCancel = true,
  isConfirmEnabled = true,
  isOpen,
  hideButtons,
}) {
  const [state, setState] = useState(STATES.CLOSED);
  const [isCancelPressed, setIsCancelPressed] = useState(false);
  const [isConfirmPressed, setIsConfirmPressed] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (isOpen && state === STATES.CLOSED) {
      setState(STATES.OPENING);
    } else if (!isOpen && state === STATES.OPEN) {
      setState(STATES.CLOSING);
    }
  }, [isOpen, state]);

  useEffect(() => {
    if (state === STATES.OPENING) {
      timeoutRef.current = setTimeout(() => {
        setState(STATES.OPEN);
      }, 300); // This should match your CSS animation duration
    } else if (state === STATES.CLOSING) {
      timeoutRef.current = setTimeout(() => {
        setState(STATES.CLOSED);
      }, 300); // This should match your CSS animation duration
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [state]);

  const handleOverlayClick = (e) => {
    if (
      canTapOutToCancel &&
      e.target === e.currentTarget &&
      state === STATES.OPEN
    ) {
      onCancel();
    }
  };

  const handleCancelPress = () => {
    setIsCancelPressed(true);
    onCancel();
    playSoundDeselect && playSoundDeselect();
  };

  const handleConfirmPress = () => {
    if (isConfirmEnabled) {
      setIsConfirmPressed(true);
      onConfirm();
      playSoundSelectionPrimary && playSoundSelectionPrimary();
    }
  };

  if (state === STATES.CLOSED) return null;

  return (
    <div
      className={`bottom-sheet-overlay ${state}`}
      onClick={handleOverlayClick}
    >
      <div className={`bottom-sheet ${state}`}>
        <div className="bottom-sheet-background">
          <img
            src={bottomSheetBGLeft}
            alt=""
            className="bottom-sheet-bg-left"
          />
          <img
            src={bottomSheetBGCenterSliver}
            alt=""
            className="bottom-sheet-bg-center"
          />
          <img
            src={bottomSheetBGRight}
            alt=""
            className="bottom-sheet-bg-right"
          />
        </div>
        <div className="bottom-sheet-content">{children}</div>
        {!hideButtons && (
          <div className="bottom-sheet-buttons">
            <button
              className="bottom-sheet-button cancel"
              onClick={handleCancelPress}
              onTouchStart={() => setIsCancelPressed(true)}
              onTouchEnd={() => setIsCancelPressed(false)}
              onMouseDown={() => setIsCancelPressed(true)}
              onMouseUp={() => setIsCancelPressed(false)}
              onMouseLeave={() => setIsCancelPressed(false)}
            >
              <img
                src={isCancelPressed ? cancelButtonPressed : cancelButton}
                alt="Cancel"
              />
            </button>
            <button
              className={`bottom-sheet-button confirm ${
                isConfirmEnabled ? "" : "disabled"
              }`}
              onClick={handleConfirmPress}
              onTouchStart={() => isConfirmEnabled && setIsConfirmPressed(true)}
              onTouchEnd={() => setIsConfirmPressed(false)}
              onMouseDown={() => isConfirmEnabled && setIsConfirmPressed(true)}
              onMouseUp={() => setIsConfirmPressed(false)}
              onMouseLeave={() => setIsConfirmPressed(false)}
              disabled={!isConfirmEnabled}
            >
              <img
                src={
                  !isConfirmEnabled
                    ? confirmButtonDisabled
                    : isConfirmPressed
                    ? confirmButtonPressed
                    : confirmButton
                }
                alt="Confirm"
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default BottomSheet;
