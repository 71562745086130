export function generateRoomName(userId1, userId2) {
  const [first, second] = [userId1, userId2].sort();
  const combined = first + second;
  const hash = Array.from(combined).reduce((acc, char) => {
    return ((acc << 5) - acc + char.charCodeAt(0)) | 0;
  }, 0);

  const sections = [
    hash & 0xffffffff,
    (hash >> 32) & 0xffff,
    0x4000 | ((hash >> 48) & 0x0fff),
    0x8000 | ((hash >> 64) & 0x3fff),
    (hash >> 80) & 0xffffffff,
  ].map((section) => section.toString(16).padStart(8, "0"));

  return sections.join("-");
}
