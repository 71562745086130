import React from "react";
import CallToActionHeader from "../CallToActionHeader";
import armoryCTAHeaderText from "../../assets/images/armoryCTAHeaderText.png";
import "../../styles/ArmoryScreen.css";

function ArmoryScreen({ dailyQuests, quests }) {
  return (
    <div className="quests-screen">
      <CallToActionHeader CTAHeaderTextIcon={armoryCTAHeaderText} />
      <div className="coming-soon-container">
        <div className="coming-soon">{"COMING SOON"}</div>
      </div>
    </div>
  );
}

export default ArmoryScreen;
