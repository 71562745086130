import React, { useState, useEffect, useCallback, useRef } from "react";
import BackgroundContainer from "./components/BackgroundContainer";
import Header from "./components/Header";
import NavigationBar from "./components/NavigationBar";
import ScreenContainer from "./components/ScreenContainer";
import UnityGame from "./components/UnityGame";
import DebugLog from "./components/DebugLog";
import LoadingComponentFade from "./components/LoadingComponentFade";
import { preloadImages } from "./utils/ImagePreloader";
import { logToGlobalLogger } from "./utils/GlobalLogger";
import { generateFriendInviteLink } from "./utils/FriendLinkUtils";
import { generateRoomName } from "./utils/RoomNameGenerator";
import {
  playSoundSelectionPrimary,
  playSoundNavItemSwitch,
  playSoundBattle,
  playSoundBOTBK,
  stopSoundBOTBK,
} from "./utils/AudioManager";
import {
  authenticateWithTelegramInitData,
  fetchUserInfo,
  fetchUserFriends,
  updateUserData,
} from "./services/apiService";
import "./styles/App.css";
import "./styles/fonts.css";

import BeefyBoyImage from "./assets/images/beefyBoy.png";
import ValkyrieImage from "./assets/images/siren.png";
import TryHardImage from "./assets/images/tryHard.png";
import bkLogoCentered from "./assets/images/bkLogoCentered.png";
import BottomSheet from "./components/BottomSheet";

const CHARACTER_ARRAY = [
  {
    id: "BEEFY_BOY",
    name: "Beefy Boy",
    image: BeefyBoyImage,
    attack: 40,
    defense: 99,
    charge: 20,
    passive: "Reverse 33% of blocked incoming deadblocks",
    passiveTitle: "Defense Wins Championships",
    ultimate: "Immunize board against deadblocks for 10 seconds",
    ultimateTitle: "Beefy Shield",
  },
  {
    id: "SIREN",
    name: "Siren",
    image: ValkyrieImage,
    attack: 85,
    defense: 40,
    charge: 45,
    passive: "Lower deadblock spawn threshold by 10%",
    passiveTitle: "Mana Spam",
    ultimate: "Increase opponent's board 'Pace' by 15% for 10 seconds",
    ultimateTitle: "Ether Storm",
  },
  {
    id: "TRY_HARD",
    name: "Tryhard Knight Guy",
    image: TryHardImage,
    attack: 60,
    defense: 60,
    charge: 70,
    passive: "Grants a 30% 'Combo Streak' bonus for full board connections",
    passiveTitle: "Maximum Effort",
    ultimate:
      "Wedges a sword into opponent's board, disrupting connections for 20 seconds",
    ultimateTitle: "Double Edged Sword",
  },
];

const HARDCODED_QUESTS = {
  dailyQuests: [
    {
      name: "Daily Challenge",
      onSelect: () => console.log("Daily Quest 1 selected"),
    },
    {
      name: "Daily Spoils",
      onSelect: () => console.log("Daily Quest 2 selected"),
    },
  ],
  quests: [
    { name: "Follow BK on X", onSelect: () => console.log("Quest 1 selected") },
    {
      name: "Join the BK Telegram Channel",
      onSelect: () => console.log("Quest 2 selected"),
    },
  ],
};

function App() {
  const [currentScreen, setCurrentScreen] = useState("Battle");
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [ethereumAddress, setEthereumAddress] = useState("");
  const [showBattleModal, setShowBattleModal] = useState(false);
  // const [showEthAddressModal, setShowEthAddressModal] = useState(false);
  const [battleModalOpacity, setBattleModalOpacity] = useState(0);
  const [isBattleModalInteractive, setIsBattleModalInteractive] =
    useState(false);
  const [currentCharacterIndex, setCurrentCharacterIndex] = useState(0);
  const [showDebugLog, setShowDebugLog] = useState(false);
  const [showIntroBottomSheet, setShowIntroBottomSheet] = useState(false);
  const [quests] = useState(HARDCODED_QUESTS);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [friendBattleInfo, setFriendBattleInfo] = useState(null);

  const unityGameRef = useRef(null);

  const handleEthAddressSubmit = async (address) => {
    try {
      logToGlobalLogger("App.js: submitting ethaddress");

      if (!/^0x[a-fA-F0-9]{40}$/.test(address)) {
        logToGlobalLogger("App.js: Invalid Ethereum address format");
        throw new Error("Invalid Ethereum address format");
      }

      logToGlobalLogger("App.js: Ethaddress update:", address);
      const updatedUser = await updateUserData({ ethWalletAddress: address });

      setEthereumAddress(updatedUser.ethWalletAddress);

      console.log(`Ethereum address saved: ${updatedUser.ethWalletAddress}`);
      logToGlobalLogger(
        `App.js:Ethereum address saved: ${updatedUser.ethWalletAddress}`
      );
    } catch (error) {
      console.error("Error updating Ethereum address:", error);
      logToGlobalLogger(
        `App.js: Error updating Ethereum address: ${error.message}`
      );
    }
  };

  const handleBattlePressed = useCallback(() => {
    setFriendBattleInfo(null); // Reset friend battle info for standard battles
    stopSoundBOTBK && stopSoundBOTBK();
    playSoundBattle && playSoundBattle();

    setShowBattleModal(true);
    setTimeout(() => {
      setBattleModalOpacity(1);
      setTimeout(() => {
        setIsBattleModalInteractive(true);
      }, 300);
    }, 50);
  }, []);

  const handleBattleFriendRowPressed = useCallback(
    (friend) => {
      const roomName = generateRoomName(userInfo.userId, friend.userId);
      setFriendBattleInfo({
        roomName,
        opponentUsername: friend.username,
      });

      stopSoundBOTBK && stopSoundBOTBK();
      playSoundBattle && playSoundBattle();

      setShowBattleModal(true);
      setTimeout(() => {
        setBattleModalOpacity(1);
        setTimeout(() => {
          setIsBattleModalInteractive(true);
        }, 300);
      }, 50);
    },
    [userInfo]
  );

  const handleInviteFriendPressed = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      const inviteLink = generateFriendInviteLink(userInfo?.userId);
      const message = `${inviteLink}\n\nBattle me in Brutal Blocks!`;
      window.Telegram.WebApp.openTelegramLink(
        `https://t.me/share/url?url=${encodeURIComponent(message)}`
      );
    } else {
      console.error("Telegram WebApp is not available");
    }
  };

  const handleCopyInviteLinkPressed = () => {
    const inviteLink = generateFriendInviteLink(userInfo?.userId);
    navigator.clipboard.writeText(inviteLink).then(() => {
      setShowCopiedMessage(true);
      setTimeout(() => setShowCopiedMessage(false), 1300);
    });
  };

  const closeBattleModal = useCallback(async () => {
    setIsBattleModalInteractive(false);
    setBattleModalOpacity(0);
    await new Promise((resolve) => setTimeout(resolve, 300));
    if (unityGameRef.current) {
      try {
        await unityGameRef.current.unloadUnity();
      } catch (error) {
        console.error("Error unloading Unity:", error);
        logToGlobalLogger(`App.js: Error unloading Unity: ${error.message}`);
      }
    }
    setShowBattleModal(false);
  }, []);

  const handleNextCharacterPressed = () => {
    playSoundSelectionPrimary && playSoundSelectionPrimary();
    setCurrentCharacterIndex(
      (prevIndex) => (prevIndex + 1) % CHARACTER_ARRAY.length
    );
  };

  const handlePrevCharacterPressed = () => {
    playSoundSelectionPrimary && playSoundSelectionPrimary();
    setCurrentCharacterIndex(
      (prevIndex) =>
        (prevIndex - 1 + CHARACTER_ARRAY.length) % CHARACTER_ARRAY.length
    );
  };

  const onNavItemPress = (newScreen) => {
    playSoundNavItemSwitch && playSoundNavItemSwitch();
    setCurrentScreen(newScreen);
  };

  const onMatchConcluded = async () => {
    try {
      const [userInfoResult, userFriendsResult] = await Promise.allSettled([
        fetchUserInfo(),
        fetchUserFriends(),
      ]);

      if (userInfoResult.status === "fulfilled") {
        const userInfoNew = userInfoResult.value;
        logToGlobalLogger("userInfo: " + JSON.stringify(userInfoNew, null, 2));
        logToGlobalLogger("userId: " + userInfoNew.userId);

        if (userFriendsResult.status === "fulfilled") {
          userInfoNew.brutalBlocksFriends = userFriendsResult.value;
          logToGlobalLogger(
            "userFriends: " + JSON.stringify(userFriendsResult.value, null, 2)
          );
        } else {
          console.error(
            "Error fetching user friends:",
            userFriendsResult.reason
          );
          logToGlobalLogger(
            "Error fetching user friends: " + userFriendsResult.reason.message
          );
        }

        setUserInfo(userInfoNew);
      } else {
        console.error("Error fetching user info:", userInfoResult.reason);
        logToGlobalLogger(
          "Error fetching user info: " + userInfoResult.reason.message
        );
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      logToGlobalLogger("Unexpected error: " + error.message);
    }
  };

  const onUnityQuit = async () => {
    try {
      onMatchConcluded();
      closeBattleModal();
      playSoundBOTBK && playSoundBOTBK();
    } catch (error) {
      console.error("Error in onUnityQuit App.js:", error);
      logToGlobalLogger("Error in onUnityQuit App.js: " + error.message);
    }
  };

  useEffect(() => {
    const initializeTelegramWebApp = async () => {
      return new Promise((resolve) => {
        if (window.Telegram && window.Telegram.WebApp) {
          const webApp = window.Telegram.WebApp;

          webApp.expand();
          webApp.disableVerticalSwipes();

          webApp.ready();
          console.log("Telegram WebApp initialized and expanded");
        } else {
          console.error("Telegram WebApp is not available");
        }
        resolve();
      });
    };

    async function initializeApp() {
      try {
        await initializeTelegramWebApp();

        if (!window.TelegramGameProxy) {
          console.error("TelegramGameProxy is not available");
        }

        logToGlobalLogger("App.js: href: ", window?.location?.href);

        const initData = window.Telegram.WebApp.initData;

        if (initData) {
          preloadImages();
          logToGlobalLogger(
            "App.js: initData: ",
            JSON.stringify(initData, null, 2)
          );
          logToGlobalLogger("App.js: Starting authentication using initData");

          const authData = await authenticateWithTelegramInitData(initData);

          if (authData?.user != null) {
            logToGlobalLogger(
              "App.js: userInfo: " + JSON.stringify(authData.user, null, 2)
            );
            logToGlobalLogger("App.js: userId: " + authData.user.userId);

            let userFriends;
            try {
              userFriends = await fetchUserFriends();
              logToGlobalLogger(
                "App.js: userFriends: " + JSON.stringify(userFriends, null, 2)
              );
            } catch (error) {
              console.error("Error fetching user friends:", error);
              logToGlobalLogger(
                "App.js: Error fetching user friends: " + error.message
              );
            }

            const updatedUserInfo = {
              ...authData.user,
              brutalBlocksFriends:
                userFriends || authData.user.brutalBlocksFriends,
            };

            setUserInfo(updatedUserInfo);

            if (updatedUserInfo.ethWalletAddress) {
              setEthereumAddress(updatedUserInfo.ethWalletAddress);
            }
          }
        } else {
          logToGlobalLogger("App.js: No initData available");
          console.error("No initData available");
          // TODO: Handle cases where initData is not available
          // For example, prompt the user to open the app from Telegram
        }
      } catch (error) {
        console.error("Authentication or user info fetch failed:", error);
        logToGlobalLogger(
          "App.js: Authentication or user info fetch failed with error: " +
            error
        );
      } finally {
        setIsLoading(false);
        setTimeout(() => {
          setShowIntroBottomSheet(true);
        }, 1100);
      }
    }

    initializeApp();
  }, []);

  useEffect(() => {
    const playInitialSound = () => {
      playSoundBOTBK && playSoundBOTBK();
      // Remove the event listener after playing
      document.removeEventListener("click", playInitialSound);
    };

    // Add event listener for the first click
    document.addEventListener("click", playInitialSound);

    // Clean up the event listener if the component unmounts
    return () => {
      document.removeEventListener("click", playInitialSound);
    };
  }, []);

  if (isLoading) {
    return <LoadingComponentFade shouldFadeOut={false} />;
  }

  const userName =
    userInfo?.tgUserInfo?.username ||
    userInfo?.tgUserInfo?.first_name ||
    "BRUTAL BLOCKS";

  return (
    <div className="app-container">
      <BackgroundContainer />
      <Header
        playerName={userName}
        level={0}
        maxLevel={11}
        lootAmount={userInfo?.brutalBlocksLoot || 0}
        bountyBoost={userInfo?.brutalBlocksBountyBoost || 1}
        onTitleSecretTap={setShowDebugLog}
      />
      <div className="app-content-container">
        <div className="app-top-content-container">
          <ScreenContainer
            currentScreen={currentScreen}
            userInfo={userInfo}
            onBattlePressed={handleBattlePressed}
            onBattleFriendRowPressed={handleBattleFriendRowPressed}
            onInviteFriendPressed={handleInviteFriendPressed}
            onCopyInviteLinkPressed={handleCopyInviteLinkPressed}
            onNextCharacterPressed={handleNextCharacterPressed}
            onPrevCharacterPressed={handlePrevCharacterPressed}
            currentCharacterIndex={currentCharacterIndex}
            characterArray={CHARACTER_ARRAY}
            onEthAddressSubmit={handleEthAddressSubmit}
            ethereumAddress={ethereumAddress}
            quests={quests.quests}
            dailyQuests={quests.dailyQuests}
          />
        </div>
      </div>
      <NavigationBar
        currentScreen={currentScreen}
        onScreenChange={onNavItemPress}
      />
      <BottomSheet
        isOpen={showIntroBottomSheet}
        onCancel={() => {
          setShowIntroBottomSheet(false);
        }}
        canTapOutToCancel={true}
        hideButtons={true}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 28,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <img
            style={{ height: 100, width: 100, marginBottom: 4 }}
            src={bkLogoCentered}
            alt={"Brutal Knights Logo"}
          />
          <div style={{ fontSize: 21, width: 330 }}>
            {"Welcome to Brutal Blocks!"}
          </div>
          <div style={{ fontSize: 20 }}>{"(Pre-Alpha)"}</div>
          <div
            style={{
              marginTop: 26,
              width: 330,
              fontSize: 15,
              textAlign: "center",
              fontFamily: "Microgramma Medium",
            }}
          >
            {
              "Choose your character wisely, test your Tetris skills in real-time PVP, and rise up the Loot Leaderboard! Don't think too hard, it's pretty much just Tetris."
            }
          </div>

          <div
            style={{
              marginTop: 30,
              width: 330,
              fontSize: 12,
              textAlign: "center",
              fontFamily: "Microgramma Medium",
            }}
          >
            {
              "**DISCLAIMER** The current experience is optimized for web and Android - we are aware of an issue on certain iOS devices where the app crashes during loading. To get the best experience , kindly close all other apps while we resolve this issue."
            }
          </div>
        </div>
      </BottomSheet>
      {showCopiedMessage && (
        <div className="copied-message">Invite Link Copied</div>
      )}
      {showBattleModal && (
        <div
          className="battle-modal"
          style={{
            opacity: battleModalOpacity,
            transition: "opacity 0.3s ease-in-out",
            pointerEvents: isBattleModalInteractive ? "auto" : "none",
          }}
        >
          <div className="battle-modal-content">
            <div className="unity-game-container">
              <UnityGame
                elo={userInfo?.brutalBlocksElo || 600}
                character={CHARACTER_ARRAY[currentCharacterIndex].id}
                userId={userInfo?.userId}
                onUnityQuit={onUnityQuit}
                roomName={friendBattleInfo?.roomName}
                opponentUsername={friendBattleInfo?.opponentUsername}
              />
            </div>
          </div>
        </div>
      )}
      <DebugLog visible={showDebugLog} onClose={() => setShowDebugLog(false)} />
      <LoadingComponentFade />
    </div>
  );
}

export default App;
