import React from "react";
import CallToActionHeader from "../CallToActionHeader";
import SectionHeader from "../SectionHeader";
import ItemButton from "../ItemButton";
import questsCTAHeaderText from "../../assets/images/questsCTAHeaderText.png";
import "../../styles/QuestsScreen.css";

function QuestsScreen({ dailyQuests, quests }) {
  return (
    <div className="quests-screen">
      <CallToActionHeader CTAHeaderTextIcon={questsCTAHeaderText} />

      <SectionHeader sectionName="DAILY QUESTS... COMING SOON" />
      <div className="quest-list">
        {dailyQuests.map((quest, index) => (
          <ItemButton
            key={index}
            title={quest.name}
            onSelected={quest.onSelect}
            disabled={true}
          />
        ))}
      </div>

      <SectionHeader sectionName="QUEST LIST... COMING SOON" />
      <div className="quest-list">
        {quests.map((quest, index) => (
          <ItemButton
            key={index}
            title={quest.name}
            onSelected={quest.onSelect}
            disabled={true}
          />
        ))}
      </div>
    </div>
  );
}

export default QuestsScreen;
